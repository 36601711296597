<template>
  <section>
    <Toast />
    <div class="bg-white my-2 rounded-md p-4">
      <router-view></router-view>
    </div>
  </section>
</template>
<script>
  import { onMounted } from 'vue'
  export default {
    name: 'configuracionMipres',
    setup () {
      onMounted(() => {
      })
      return {
      }
    }
  }
</script>
<style scoped>
</style>
